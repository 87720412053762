<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('ball_game.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('ball_game.head_data') }}</v-card-title>

        <v-form ref="form">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col cols="12" xs="6" md="6" xl="6">
                <v-text-field v-model="formData.title" :label="$t('ball_game.detail_input1')" outlined dense
                  disabled></v-text-field>
              </v-col>

              <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                <v-text-field v-model="formData.round" :label="$t('ball_game.detail_input2')" type="date" :min="minDate"
                  outlined dense disabled></v-text-field>
              </v-col>

              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeStart" :label="$t('ball_game.detail_input7')" type="date"
                    :min="minDate" outlined dense disabled></v-text-field></v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeEnd" :label="$t('ball_game.detail_input8')" type="date"
                    :min="minDate" outlined dense disabled></v-text-field></v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.typeBetUse" :items="rewardTypes" item-text="name" item-value="value"
                    :label="$t('ball_game.detail_input9')" outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.betUse" :label="$t('ball_game.detail_input10')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>

              <v-col class="pa-0">
                <v-row class="ma-0">
                  <v-card-title class="pt-0 pb-2 px-3">{{ $t('ball_game.reward') }}</v-card-title>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                    <v-select v-model="formData.typeReward" :items="rewardTypes" :label="$t('ball_game.detail_input11')"
                      outlined dense disabled></v-select>
                  </v-col>
                  <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                    <v-text-field v-model="formData.rewardAmount" :label="$t('ball_game.detail_input12')" type="number"
                      outlined dense disabled></v-text-field>
                  </v-col>
                  <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                    <v-text-field v-model="formData.multiplyTurnOver" :label="$t('ball_game.reward_multiple')"
                      type="number" outlined dense disabled></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

          <!-- Reward type and amount Input -->
          <v-col>
            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('ball_game.head1') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[0].homeName" :label="$t('ball_game.detail_input3')" outlined dense
                    disabled></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[0].homeScore || '-'" :label="$t('ball_game.detail_input5')"
                    outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[0].awayScore || '-'" :label="$t('ball_game.detail_input6')"
                    outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[0].awayName" :label="$t('ball_game.detail_input4')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('ball_game.head2') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[1].homeName" :label="$t('ball_game.detail_input3')" outlined dense
                    disabled></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[1].homeScore || '-'" :label="$t('ball_game.detail_input5')"
                    outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-text-field v-model="formData.match[1].awayScore || '-'" :label="$t('ball_game.detail_input6')"
                    outlined dense disabled></v-text-field>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-text-field v-model="formData.match[1].awayName" :label="$t('ball_game.detail_input4')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'predict-football' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3">{{ $t('back') }}</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      title: this.$t('ball_game.head_list3'),
      formData: {
        title: '',
        round: '',
        timeStart: '',
        timeEnd: '',
        typeBetUse: 'coin',
        betUse: 1,
        typeReward: 'money',
        rewardAmount: null,
        multiplyTurnOver: 0,
        matchAmount: 2,
        match: [
          { homeName: '', awayName: '' },
          { homeName: '', awayName: '' },
        ],
      },
      minDate: moment().format('YYYY-MM-DD'),
      rewardTypes: ['point', 'coin', 'money'],

      breadcrumbsItems: [
        {
          text: this.$t('ball_game.head'),
          disabled: false,
          href: '/predictFootball',
        },
        {
          text: this.$t('ball_game.head_list4'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.getFootballData()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'ทายบอล',
              url: window.location.href,
              detail: 'รายละเอียด ทายบอล',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getFootballData() {
      const id = this.$route.params.id
      this.loading = true
      try {
        let res = await this.$store.dispatch('getFootballList', `id=${id}`)
        let resData = res.data[0]

        this.formData = {
          title: resData.title,
          round: moment(resData.round).format('YYYY-MM-DD') || null,
          timeStart: moment(resData.timeStart).format('YYYY-MM-DD') || null,
          timeEnd: moment(resData.timeEnd).format('YYYY-MM-DD') || null,
          typeBetUse: resData.typeBetUse,
          betUse: resData.betUse,
          typeReward: resData.typeReward,
          rewardAmount: resData.rewardAmount,
          multiplyTurnOver: resData.multiplyTurnOver,
          match: resData.match,
        }

        this.loading = false
      } catch (e) { }
    },
  },
}
</script>
